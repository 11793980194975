import React from 'react';
import { Avatar, Blockquote, Carousel, Rating, theme } from 'flowbite-react';
import { review, from, photoURL, profession } from '../../constants';


const SlideFive = () => {

    const testimonials = [
        {
            id: 1,
            review: "The Virtual Assistant provided by AI Assistant Inc. has transformed the way I manage my daily tasks. It's like having a personal assistant without the overhead costs. Highly recommended for professionals seeking productivity enhancements.",
            from: "Johnathan Smith",
            profession: "Marketing Director at TechSavvy Co.",
            photoURL: "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            id: 2,
            review: "The AI-driven Financial Advisor from MoneyGenius has been instrumental in optimizing my investment portfolio. Its insights and recommendations are spot-on, leading to significant gains in my wealth management strategy.",
            from: "Emily Chen",
            profession: "Investment Analyst at Wealthwise LLC",
            photoURL: "https://randomuser.me/api/portraits/women/2.jpg"
        },
        {
            id: 3,
            review: "The AI-powered Health Coach from Wellbotics has revolutionized my fitness journey. Its personalized workout plans and nutritional advice have helped me achieve my fitness goals faster than I ever thought possible. A game-changer for anyone serious about their health.",
            from: "Michael Thompson",
            profession: "Fitness Instructor at FitFlex Gym",
            photoURL: "https://randomuser.me/api/portraits/men/3.jpg"
        },
        {
            id: 4,
            review: "The AI Writing Assistant by ContentCrafters has been a lifesaver for my content creation process. Its grammar suggestions and content ideas have elevated the quality of my writing, saving me countless hours of editing. A must-have tool for any writer serious about their craft.",
            from: "Sophie Davis",
            profession: "Freelance Writer & Blogger",
            photoURL: "https://randomuser.me/api/portraits/women/4.jpg"
        },
        {
            id: 5,
            review: "The AI-based Language Learning App from LinguaTech has made learning a new language an enjoyable experience. Its interactive lessons and speech recognition technology have accelerated my language proficiency in record time. I highly recommend it to anyone looking to learn a new language efficiently.",
            from: "Carlos Rodriguez",
            profession: "International Business Consultant",
            photoURL: "https://randomuser.me/api/portraits/men/5.jpg"
        }
    ];


    return (
        <div className="h-56 sm:h-64 xl:h-80 2xl:h-96  dark:bg-black">
            <Carousel slideInterval={1500} >
                {testimonials.map(testimonial => (
                    <div className='dark:bg-black'>
                        <figure className="max-w-screen-md mx-auto my-16 flex flex-col items-center dark:bg-black">
                            <div className="mb-4 flex items-center">
                                <Rating size="md">
                                    <Rating.Star color='black' className='dark:fill-white' />
                                    <Rating.Star color='black' className='dark:fill-white' />
                                    <Rating.Star color='black' className='dark:fill-white' />
                                    <Rating.Star color='black' className='dark:fill-white' />
                                    <Rating.Star color='black' className='dark:fill-white' />
                                </Rating>
                            </div>
                            <Blockquote>
                                <p className="text-1xl max-md:text-lg max-md:px-2 font-bold text-black dark:text-white text-center">
                                    {testimonial.review}
                                </p>
                            </Blockquote>
                            <figcaption className="mt-6 flex items-center space-x-3">
                                <Avatar rounded size="xs" img={testimonial.photoURL} alt="profile picture" />
                                <div className="flex items-center divide-x-2 divide-black dark:divide-white">
                                    <cite className="pr-3 font-bold text-black dark:text-white">{testimonial.from}</cite>
                                    <cite className="pl-3 text-sm font-normal text-black dark:text-white">{testimonial.profession}</cite>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default SlideFive;
