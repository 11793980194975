//BRAND
export const name = 'AiLearnxt';
export const company = 'AiLearnxt';
export const websiteURL = 'http://ailearnxt.com';
export const serverURL = 'https://learnxtai.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/india-bd34b.appspot.com/o/artificial-intelligence.png?alt=media&token=2dc0da1c-12d0-4dd2-b908-cb8e57fcf3f0';
export const razorpayEnabled = true;
export const paypalEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 4;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 40;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Raajastra Harish";
export const profession = 'AiLearnxt';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/india-bd34b.appspot.com/o/artificial-intelligence.png?alt=media&token=2dc0da1c-12d0-4dd2-b908-cb8e57fcf3f0';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_hAeL6BbbI7JRpc";
export const razorpayPlanIdOne = "plan_O6o9HWio1X7T4B";
export const razorpayPlanIdTwo = "plan_O6oIa6skWt1XZS";
