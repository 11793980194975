import React from 'react';
import slide from '../../res/img/slideOne.png'
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';
import backgroundImage from '../../res/img/bg.png';

const SlideOne = () => {

    const navigate = useNavigate();

    function redirectSignIn() {
        navigate("/signin");
    }
    function redirectSignUp() {
        navigate("/signup");
    }


    return (
        <div className="flex flex-col items-center dark:bg-black py-44" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

            <h1 className="text-6xl font-black mt-20 max-md:text-3xl dark:text-white">Ai Course Generator</h1>

            <div className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed out once, initially
                        'Revolutionize your learning journey',
                        1000, // wait 1s before replacing "Mice" with "Hamsters"
                        'AI Course Generator SaaS',
                        1000,
                        'Effortlessly create engaging',
                        1000,
                        'personalized courses tailored to your needs',
                        1000
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: '2em', display: 'inline-block' }}
                    repeat={Infinity}
                />
            </div>

            <p className="text-center text-black mt-12 max-w-md font-medium max-md:text-xs dark:text-white">Join and establish your AI text-to-course account to embark on your learning journey</p>

            <div className="flex space-x-4 mb-4 mt-6">
                <button onClick={redirectSignUp} className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black">
                    SignUp
                </button>
            </div>

        </div>
    );
};

export default SlideOne;
