import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'flowbite-react';
const SlideSix = () => {

    const navigate = useNavigate();

    function redirectSignIn() {
        navigate("/signin");
    }
    function redirectSignUp() {
        navigate("/signup");
    }

    function redirectCancel() {
        navigate("/cancellation");
    }

    function redirectRefund() {
        navigate("/refund");
    }

    function redirectBilling() {
        navigate("/billing");
    }

    function redirectContact() {
        navigate("/contact");
    }

    const data = [
        { question: 'What is our cancellation policy ?', answer: <p>You can read our cancellation policy from <p className='text-blue-800 underline' onClick={redirectCancel}>here</p></p> },
        { question: 'What is our refund policy ?', answer: <p>You can read our refund policy from <p className='text-blue-800 underline' onClick={redirectRefund}>here</p></p> },
        { question: 'What is our subscription and billing policy ?', answer: <p>You can read our subscription and billing policy from <p className='text-blue-800 underline' onClick={redirectBilling}>here</p></p> },
        { question: 'What are the available payment gateways ?', answer: 'Our platform supports multiple payment gateways, including PayPal, and Razorpay.' },
    ];
    const [expandedItem, setExpandedItem] = useState(null);

    const handleExpand = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };
    return (
        <div className='dark:bg-black px-6 pb-16'>
            <div className="flex items-center justify-center text-center flex-col px-6 max-md:px-2 mb-10">
                <h1 className="text-4xl font-black dark:text-white max-md:text-2xl mb-10">Frequently asked questions</h1>
                <ul>
                    {data.map((item, index) => (
                        <li key={index} className="mb-4">
                            <Button
                                className='w-96 max-sm:max-w-xs mb-6 items-start justify-start text-start border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-bold rounded-none  enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'
                                onClick={() => handleExpand(index)}
                            >
                                {data[index].question}
                            </Button>
                            {expandedItem === index && (
                                <div className='max-w-sm max-sm:max-w-xs mb-6 items-start justify-start text-start border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-normal rounded-none enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'>
                                    {item.answer}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex flex-col max-md:items-center justify-between border border-black bg-white py-10 px-16 dark:border-white dark:bg-black md:flex-row">
                <div className="mb-4 md:mb-0 md:mr-4 max-md:text-center">
                    <h2 className="mb-2 text-4xl max-md:text-2xl font-black dark:text-white">Get Started</h2>
                    <p className="flex items-center font-normal max-md:text-xs text-black dark:text-white">
                        Embark on your learning journey with simplicity Get Started Today.
                    </p>
                </div>
                <div className="flex flex-shrink-0 items-center">
                    <p
                        onClick={redirectSignIn}
                        className="mr-3 inline-flex items-center justify-center border-black text-black border px-3 py-2 font-medium dark:border-white dark:text-white"
                    >

                        SignIn
                    </p>
                    <p
                        onClick={redirectSignUp}
                        className="mr-2 inline-flex items-center justify-center bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black"
                    >
                        SignUp

                    </p>

                </div>
            </div>
        </div>
    );
};

export default SlideSix;
