import React from 'react';
import { useNavigate } from "react-router-dom";
import { Card } from 'flowbite-react';

const SlideSeven = () => {

    const navigate = useNavigate();

    function redirectSignIn() {
        navigate("/signin");
    }
    function redirectSignUp() {
        navigate("/signup");
    }

    const cardsFeatures = [
        {
            id: 1,
            title: 'Topic Input',
            description: 'Easily enter topics and subtopics with an intuitive interface',
        },
        {
            id: 2,
            title: 'Course Type Preferences',
            description: 'Choose between Image + Theory or Video + Theory formats for a personalized learning journey',
        },
        {
            id: 3,
            title: 'AI-Powered Generation',
            description: 'Our advanced AI algorithms analyze your inputs to generate comprehensive courses',
        },
        {
            id: 4,
            title: 'Learning Styles',
            description: 'Accommodate different learning styles to focus on images, videos, or textual content',
        },
        {
            id: 5,
            title: 'Personalized Curriculum',
            description: 'Receive a uniquely crafted curriculum based on your preferences',
        },
        {
            id: 6,
            title: 'Real-time Preview',
            description: 'See a real-time preview of your generated course before finalizing',
        },
    ];

    const style = {
        "root": {
            "base": "max-w-sm flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }

        }
    }


    return (
        <div className='dark:bg-black px-6 pb-16 pt-4'>
            <div className='flex-1 flex flex-col items-center justify-center'>
                <h1 className="text-6xl font-black mt-14 max-md:text-3xl dark:text-white">Features</h1>
                <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                    Craft your courses Instantly
                </p>
                <div className='mt-16 flex flex-wrap items-center justify-center'>
                    {cardsFeatures.map((card) => (
                        <Card key={card.id} theme={style}>
                            <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                {card.title}
                            </h5>
                            <p className='font-normal text-sm text-black dark:text-white'>{card.description}</p>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SlideSeven;
